import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';
import { Pill } from 'ui-components';

export default function UserRoleLabel(props: { role: Domain.Role | null; showOnlyText?: boolean }) {
    const { gettext } = useGettext();
    let userRoleLabel = '';

    switch (props.role) {
        case 'platformAdministrator':
            userRoleLabel = gettext('Platform administrator');
            break;
        case 'branchAdministrator':
            userRoleLabel = gettext('Branch manager');
            break;
        case 'companyAdministrator':
            userRoleLabel = gettext('Company manager');
            break;
        case 'supportAgent':
            userRoleLabel = gettext('Support Agent');
            break;
        case 'displayDevice':
            userRoleLabel = gettext('Device');
            break;
        case 'apotheekOpZakApp':
            userRoleLabel = gettext('Apotheek Op Zak App');
            break;
        case 'somkoInvoiceApi':
            userRoleLabel = gettext('Somko Invoice API');
            break;
        case 'reseller':
            userRoleLabel = gettext('Reseller');
            break;
    }

    return (
        <React.Fragment>
            {props.showOnlyText ? (
                userRoleLabel
            ) : (
                <Pill
                    color="s3"
                    variant="outlined"
                >
                    {userRoleLabel}
                </Pill>
            )}
        </React.Fragment>
    );
}
