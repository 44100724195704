import * as React from 'react';

import moment from 'moment';

import { ISearchProvider } from 'utils';

import { Button } from '@/button';
import { Card } from '@/card';
import Checkbox from '@/checkbox/Checkbox';
import { Dropdown } from '@/core';
import { DateTimePicker } from '@/dateTimePicker';
import { Icon } from '@/icon';
import { Input } from '@/input';
import { Container, Row, Col } from '@/layout';
import { MenuWithSearch, Menu } from '@/menu';
import { ISelectOption } from '@/select';

import styles from './DataTable.scss';

export default function DataTableFilterGizmo(props: {
    className?: string;
    gettext: (text: string) => string;
    inputValue?: string;
    onApply: (newValue?: string) => void;
    filterName?: string | number | symbol;
    filterOptions?: ISelectOption[];
    filterOptionsPlaceholder?: string;
    filterSearchProvider?: ISearchProvider<{
        value: string;
        label: React.ReactNode;
    }>;
    filterSearchPlaceholder?: string;
    customFilter?: React.ReactNode;
    multiSelect?: boolean;
    dateTime?: string;
    headerButton?: React.ReactNode;
    anchorRight?: boolean;
}) {
    const dropdown = React.useRef<Dropdown>(null);
    const gettext = props.gettext;
    const [value, setValue] = React.useState(props.inputValue);

    return (
        <Dropdown
            ref={dropdown}
            className={styles.FilterGizmo}
            bodyClassName={`${styles.DataTableFilterGizmoBody} ${props.className}`}
            anchorRight={props.anchorRight}
            headerRenderer={(isOpen: boolean) =>
                props.headerButton ? (
                    props.headerButton
                ) : (
                    <Button
                        variant="secondary"
                        variantSize="xxs"
                        data-test-id={`filter-${props.filterName?.toString()}`}
                        startIcon={
                            <Icon
                                iconSize="m"
                                type={isOpen ? 'action_remove' : 'action_filter'}
                                className="mx-10"
                            />
                        }
                    />
                )
            }
            onOpen={() => {
                setValue(props.inputValue);
            }}
            // onMouseDown={event => {
            //     event.stopPropagation();
            // }}
            body={
                <Card
                    hSpacing="none"
                    elevated={true}
                    className="mt-11"
                >
                    <Container
                        gutter={14}
                        className="px-14"
                    >
                        <Row>
                            <Col fullWidth={true}>
                                {!props.filterOptions && !props.filterSearchProvider && !props.dateTime && !props.customFilter ? (
                                    <Input
                                        value={value}
                                        onChange={setValue}
                                        iconRight={
                                            value ? (
                                                <Button
                                                    variant="plain"
                                                    onClick={() => {
                                                        if (dropdown.current) {
                                                            dropdown.current.close();
                                                        }
                                                        props.onApply('');
                                                    }}
                                                    className="px-0"
                                                    startIcon={<Icon type="action_remove" />}
                                                />
                                            ) : null
                                        }
                                        data-test-id="filter-value"
                                    />
                                ) : null}

                                {props.dateTime ? (
                                    <DateTimePicker
                                        value={value ? moment(value, props.dateTime) : null}
                                        onChange={newDateTime => setValue(newDateTime ? newDateTime.format(props.dateTime) : '')}
                                        format={props.dateTime}
                                        notInDropdown={true}
                                    />
                                ) : null}

                                {props.filterOptions && !props.multiSelect ? (
                                    <Menu
                                        selectedItems={value ? [value] : []}
                                        onItemClick={(_1: any, item: { value: string }) => {
                                            setValue(item.value);
                                        }}
                                        placeholder={props.filterOptionsPlaceholder}
                                        maxHeight="200px"
                                        items={props.filterOptions}
                                    />
                                ) : null}

                                {props.filterOptions && props.multiSelect ? (
                                    <Menu
                                        items={props.filterOptions}
                                        selectedItems={value ? [value] : []}
                                        onItemClick={(event, item: { value: string }) => {
                                            event.preventDefault();

                                            let newValue = value ? value.split(',') : [];
                                            if (newValue.indexOf(item.value) > -1) {
                                                newValue = newValue.filter(searchedItem => searchedItem !== item.value);
                                            } else {
                                                newValue.push(item.value);
                                            }

                                            setValue(newValue.join(','));
                                        }}
                                        placeholder={props.filterOptionsPlaceholder}
                                        maxHeight="200px"
                                        itemRenderer={item => {
                                            if (item.value === '--searching--' || item.value === '--no-results--') {
                                                return item.label;
                                            }

                                            const isSelected = (value ? value.split(',') : []).indexOf(item.value) > -1;
                                            return (
                                                <Checkbox
                                                    key={item.value}
                                                    checked={isSelected}
                                                >
                                                    {item.label}
                                                </Checkbox>
                                            );
                                        }}
                                    />
                                ) : null}

                                {props.filterSearchProvider && props.multiSelect ? (
                                    <MenuWithSearch
                                        selectedItems={value ? value.split(',') : []}
                                        onItemClick={(event, item: { value: string }) => {
                                            event.preventDefault();

                                            let newValue = value ? value.split(',') : [];
                                            if (newValue.indexOf(item.value) > -1) {
                                                newValue = newValue.filter(searchedItem => searchedItem !== item.value);
                                            } else {
                                                newValue.push(item.value);
                                            }

                                            setValue(newValue.join(','));
                                        }}
                                        searchProvider={props.filterSearchProvider}
                                        searchPlaceholder={props.filterSearchPlaceholder}
                                        maxHeight="200px"
                                        noSearchInputPadding={true}
                                        itemRenderer={item => {
                                            if (item.value === '--searching--' || item.value === '--no-results--') {
                                                return item.label;
                                            }

                                            const isSelected = (value ? value.split(',') : []).indexOf(item.value) > -1;
                                            return (
                                                <Checkbox
                                                    key={item.value}
                                                    checked={isSelected}
                                                >
                                                    {item.label}
                                                </Checkbox>
                                            );
                                        }}
                                        showSelectedOnTop={true}
                                    />
                                ) : null}

                                {props.filterSearchProvider && !props.multiSelect ? (
                                    <MenuWithSearch
                                        selectedItems={value ? [value] : []}
                                        onItemClick={(_1: any, item: { value: string }) => {
                                            setValue(item.value);
                                        }}
                                        searchProvider={props.filterSearchProvider}
                                        searchPlaceholder={props.filterSearchPlaceholder}
                                        maxHeight="200px"
                                        noSearchInputPadding={true}
                                    />
                                ) : null}

                                {props.customFilter && !props.filterOptions ? props.customFilter : null}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button
                                    variant="primary"
                                    variantSize="xs"
                                    onClick={async () => {
                                        if (dropdown.current) {
                                            await dropdown.current.close();
                                        }
                                        props.onApply(value);
                                    }}
                                >
                                    {gettext('Apply')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="secondary"
                                    variantSize="xs"
                                    onClick={async () => {
                                        if (dropdown.current) {
                                            await dropdown.current.close();
                                        }
                                        props.onApply('');
                                    }}
                                >
                                    {gettext('Clear')}
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="tertiary"
                                    variantSize="xs"
                                    onClick={() => {
                                        if (dropdown.current) {
                                            dropdown.current.close();
                                        }
                                    }}
                                >
                                    {gettext('Close')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Card>
            }
        />
    );
}
