import * as React from 'react';

import classNames from 'classnames';

import { InputBase, InputBaseProps, KeyboardHandler } from '@/core';
import { Icon } from '@/icon';
import { Tx } from '@/typography';

import styles from './Toggle.scss';
export type variant = 'primary' | 'secondary' | 'error';

export type ControlledToggleProps = InputBaseProps & {
    isOn?: boolean;
    onChange?: (isOn: boolean) => void;
    forwardRef?: React.Ref<HTMLInputElement>;
    labelRight?: boolean;
    variant?: variant;
    size?: 'md' | 'sm';
};
const colorScheme: {
    [key in variant]: {
        on: string;
        off: string;
    };
} = {
    primary: {
        on: 'accent-secondary',
        off: 'tertiary',
    },
    secondary: {
        on: 'success',
        off: 'secondary',
    },
    error: {
        on: 'error',
        off: 'tertiary',
    },
};

class ControlledToggle extends InputBase<ControlledToggleProps> {
    private readonly keyHandler = new KeyboardHandler();

    protected renderInput(): React.ReactNode {
        const { isOn, name, forwardRef, children, labelRight, variant = 'primary', size = 'md' } = this.props;
        const iconClassnames = [isOn ? colorScheme[variant].on : colorScheme[variant].off, size].join(' ');
        return (
            <React.Fragment>
                <input
                    ref={forwardRef}
                    type="checkbox"
                    onChange={this.handleChange}
                    name={name}
                    checked={isOn}
                    tabIndex={-1}
                />

                {children && !labelRight ? (
                    <Tx
                        className={styles.ToggleLabel}
                        level={`body-${size}`}
                        sx={{
                            disableSelection: true,
                        }}
                    >
                        {children}
                    </Tx>
                ) : null}

                <span className={`${styles.ToggleIcon} ${labelRight ? styles.ToggleIconLabelRight : ''}`}>
                    <Icon
                        type={isOn ? 'action_toggle_on' : 'action_toggle_off'}
                        className={iconClassnames}
                    />
                </span>

                {children && labelRight ? (
                    <Tx
                        className={styles.ToggleLabel}
                        level={`body-${size}`}
                        sx={{
                            disableSelection: true,
                        }}
                    >
                        {children}
                    </Tx>
                ) : null}
            </React.Fragment>
        );
    }

    private handleChange = (): void => {
        const { isOn, onChange } = this.props;

        if (onChange) {
            onChange(!isOn);
        }
    };

    protected getTabIndex(): number {
        return this.props.disabled ? -1 : 0;
    }

    protected getOtherBaseWrapperProps(): any {
        return {
            onKeyDown: this.keyHandler.handleKey(['ENTER', 'SPACE'], () => {
                this.handleChange();
            }),
            'data-test-id': 'label-' + this.getDataTestId(),
        };
    }

    protected getClassName(): string {
        const { isOn } = this.props;

        return classNames(super.getClassName(), styles.Toggle, isOn ? styles.ToggleOn : null);
    }

    protected getStyle(): React.CSSProperties | undefined {
        return this.props.style;
    }
}

export default React.forwardRef<HTMLInputElement, ControlledToggleProps>((props, ref) => {
    return (
        <ControlledToggle
            forwardRef={ref}
            {...props}
        />
    );
});
