import { LocalizedValue, UnusableLocalizedHTMLValue, UnusableLocalizedValue } from './Localisation';
import { OrganisationType } from './Organisation';
import { DescriptionType } from './ProductDescriptions';

export type TargetGroup = 'public' | 'pharmacist' | 'doctor' | 'hospital' | 'homecare' | string;

export interface ProductDetailsSpecies {
    id: 'human' | 'veterinary' | 'phytopharmacy' | 'surface_maintenance';
    name: LocalizedValue;
}

export interface ProductDetailsOrganisation {
    id: number;
    type: OrganisationType;
    name: UnusableLocalizedValue;

    [key: string]: any;
}

export interface ProductDetailsDescription {
    id: number;
    locales: string[];
    targetGroups: TargetGroup[];
    content: UnusableLocalizedHTMLValue;
    type: DescriptionType | null;

    [key: string]: any;
}

export interface ProductDetailsAttribute {
    id: number;
    type: 'color' | 'galenic_shape' | 'dietary_restrictions' | string;
    name: UnusableLocalizedValue;

    [key: string]: any;
}

export interface ProductDetailsActiveIngredient {
    id: number;
    name: UnusableLocalizedValue;

    [key: string]: any;
}

export const PRODUCT_CONSERVATION = ['room', 'refrigerator', 'fresh', 'frozen'] as const;
export type ProductConservation = (typeof PRODUCT_CONSERVATION)[number];

export interface ProductDetails {
    publicPrice?: number | null;
    pharmacistPrice?: number | null;
    refundValueWithoutOmnio?: number | null;
    refundValueWithOmnio?: number | null;
    packageQuantity?: string | null;
    depth?: number | null;
    length?: number | null;
    weight?: number | null | string;
    width?: number | null;
    brands?:
        | {
              id: number;
              name: UnusableLocalizedValue;

              [key: string]: any;
          }[]
        | null;
    conservation?: ProductConservation | null;
    allowedSpecies?: (null | ProductDetailsSpecies)[] | null;
    activeIngredients?: ProductDetailsActiveIngredient[] | null;
    apbCategory?: {
        id: string;
        name: LocalizedValue;

        [key: string]: any;
    } | null;
    shortDescription?: UnusableLocalizedValue | null;

    organizations?: ProductDetailsOrganisation[] | null;

    atc?: string | null;
    gtin?: string[] | null;
    ctiExtended?: string | null;

    attributes?: ProductDetailsAttribute[] | null;

    descriptions?: ProductDetailsDescription[] | null;

    [key: string]: any;
}
