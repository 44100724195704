import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';
import { AnchoredPortal, AnchoredPortalPositioningProps } from '@/portal';
import { Tx } from '@/typography';

import { TooltipOverflowDetectorContext } from './TooltipOverflowDetectorContext';

import styles from './Tooltip.scss';

export type TooltipPosition = 'bottom' | 'right' | 'left' | 'top' | 'top-center' | 'bottom-center';

interface CoreProps {
    position?: TooltipPosition;
    visible?: boolean;
    maxWidth?: string;
    width?: string;
    disabled?: boolean;
    disabledIfNoOverflow?: boolean;
    canMove?: boolean;
}

type NoCustomComponent = {
    text: string | React.ReactNode;
    component?: never;
    customProps?: never;
};

type WithCustomComponent<T> = {
    text: string | React.ReactNode;
    component: React.ComponentType<T>;
    customProps?: T;
};

type WithCustomHTMLElement<T> = {
    text: string | React.ReactNode;
    component: keyof React.ReactHTML;
    customProps?: React.HTMLAttributes<T> & {
        anchorRef?: React.Ref<any>;
    };
};

type IProps<T> = BaseProps & CoreProps & (NoCustomComponent | WithCustomComponent<T> | WithCustomHTMLElement<T>);

interface IState {
    anchorElement: HTMLElement | null;
    active: boolean;
    hasOverflow: boolean;
}

export default class Tooltip<T> extends React.PureComponent<IProps<T>, IState> {
    static defaultProps = {
        position: 'bottom',
        visible: false,
    };

    constructor(props: IProps<T>) {
        super(props);

        this.state = {
            anchorElement: null,
            active: false,
            hasOverflow: false,
        };
    }

    render() {
        const {
            className,
            children,
            text,
            maxWidth,
            width,
            visible,
            disabled,
            position,
            component,
            customProps,
            disabledIfNoOverflow,
            canMove,
            ...rest
        } = this.props;
        const Component: any = this.getComponent();
        const componentProps = this.getComponentProps();

        const textStyle: any = {};

        if (maxWidth) {
            textStyle.maxWidth = maxWidth;
        }

        if (width) {
            textStyle.width = width;
        }

        return (
            <TooltipOverflowDetectorContext.Provider
                value={{
                    hasOverflow: this.state.hasOverflow,
                    setHasOverflow: hasOverflow =>
                        this.setState({
                            hasOverflow,
                        }),
                }}
            >
                <Component
                    {...rest}
                    className={classNames(styles.Tooltip, className)}
                    {...componentProps}
                    onMouseEnter={() => {
                        this.setState({
                            active: true,
                        });
                    }}
                    onMouseLeave={() => {
                        this.setState({
                            active: false,
                        });
                    }}
                    onFocus={() => {
                        this.setState({
                            active: true,
                        });
                    }}
                    onBlur={() => {
                        this.setState({
                            active: false,
                        });
                    }}
                    ref={(element: HTMLElement | null) => {
                        this.setState({
                            anchorElement: element,
                        });
                    }}
                >
                    {children}

                    <AnchoredPortal
                        anchorElement={this.state.anchorElement}
                        {...this.getAnchorPosition()}
                        className={styles.TooltipAnchor}
                        anchorCanMove={canMove}
                    >
                        <Tx
                            as="span"
                            style={textStyle}
                            className={classNames(
                                styles.TooltipText,
                                visible || this.state.active ? styles.TooltipVisible : null,
                                maxWidth ? styles['max-width'] : null,
                                width ? styles['fixed-width'] : null,
                                disabled ? styles.TooltipDisabled : null,
                                position ? styles[position] : null,
                                disabledIfNoOverflow && !this.state.hasOverflow ? styles.TooltipDisabledIfNoOverflow : null,
                            )}
                        >
                            <span>{text}</span>
                        </Tx>
                    </AnchoredPortal>
                </Component>
            </TooltipOverflowDetectorContext.Provider>
        );
    }

    private getComponent(): React.ComponentType<T> | keyof React.ReactHTML | 'span' {
        if (this.hasCustomComponent(this.props)) {
            return this.props.component;
        }

        return 'span';
    }

    private getComponentProps(): React.ComponentProps<'span'> | React.HTMLAttributes<T> | T | undefined {
        if (this.hasCustomComponent(this.props)) {
            return this.props.customProps;
        }

        return {
            tabIndex: 0,
        };
    }

    private getAnchorPosition(): AnchoredPortalPositioningProps {
        const { position } = this.props;

        let anchorVPosition: AnchoredPortalPositioningProps['anchorVPosition'] = 'center';
        let anchorHPosition: AnchoredPortalPositioningProps['anchorHPosition'];

        if (position === 'left') {
            anchorHPosition = 'left';
        }

        if (position === 'right') {
            anchorHPosition = 'right';
        }

        if (position === 'top') {
            anchorVPosition = 'top';
        }

        if (position === 'bottom') {
            anchorVPosition = 'bottom';
        }

        if (position === 'top-center') {
            anchorVPosition = 'top';
            anchorHPosition = 'center';
        }

        if (position === 'bottom-center') {
            anchorVPosition = 'bottom';
            anchorHPosition = 'center';
        }

        return {
            anchorVPosition,
            anchorHPosition,
        };
    }

    private hasCustomComponent(props: IProps<T>): props is WithCustomComponent<T> | WithCustomHTMLElement<T> {
        return (props as WithCustomComponent<T>).hasOwnProperty('component');
    }
}
