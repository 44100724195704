import { Config } from './types';

const defaultConfig: Config = {
    apiBaseUrl: 'https://platform-api.dev.lochting.com',
    deviceSignalingServerHost: 'signaling.dev.lochting.com',
    defaultLocale: 'en_BE',
    pickupCodePrefix: 'LRC',
    localCommWebSocketBaseUrl: 'ws://127.0.0.1:1337',
    lochtingDeviceBackendBaseUrl: 'http://127.0.0.1:8337',
    logging: {
        enabled: false,
        dataDog: {
            clientToken: 'pub1234xyz',
            env: 'local',
        },
    },
    videoCalling: {
        sipHost: 'meditech.pbxc.nl',
        sipWebsocketUri: 'wss://meditech.pbxc.nl:8089/ws',
        handleThroughLocalcomm: false,
    },
    hatchPhotos: {
        fetchThroughLocalcomm: false,
    },
    featureFlags: {
        disableDevDeviceSignalingAndMonitoring: false,
        allowMaticPaymentSkipWhenAmountIsZero: false,
        showOrderReviewProductConservations: true,
        enableBeamer: true,
        enableBacksideStocking: true,
        enableProductWallStockDisplayForBranches: ['branch-baldwin', 'branch-648adb8b-4dc7-420e-8304-007bcfae8a89'],
    },
    apiValidationThrowsException: true,
    useDebugConsole: true,
};

export default defaultConfig;
